.health-dashboard {
    font-family: 'Arial', sans-serif;
    margin: 20px;
    color: #333;
    position: relative; /* Add this to position your back button relative to this container */
}

.health-dashboard h2 {
    color: #444;
    margin-bottom: 10px;
    font-weight: bold; /* Make the header bold */
}

.back__button {
    background-color: grey;
    border: none;
    border-radius: 20px;
    color: white;
    padding: 15px 10px 10px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 10px 10px;
    cursor: pointer;
    transition-duration: 0.4s;
    /* box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19); */
    position: relative; /* Make the button positioned absolutely */
    top: 0; /* Align to the top of the nearest positioned ancestor */
    right: 0; /* Align to the right of the nearest positioned ancestor */
}

.back__button:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.tables-row {
    margin-top: 60px; /* Add space between the back button and the tables */
}

.table-container {
    margin-bottom: 40px;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Add this to keep your columns aligned */
}

table, th, td {
    border: 1px solid #ddd;
}

th, td {
    text-align: left;
    padding: 8px;
}

th {
    background-color: #f2f2f2;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #f1f1f1;
}

/* Ensure that Value and Score columns have the same width */
th:nth-child(2), td:nth-child(2),
th:nth-child(3), td:nth-child(3) {
    width: 30%; /* Adjust the width as needed */
}

.health-dashboard .category {
    margin-bottom: 50px;
    display: flex; /* This will make the .category a flex container */
    flex-direction: column; /* Align children in a column */
    align-items: center; /* Center children horizontally within the category */
}

.circular-progress-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
    justify-content: center; /* Centers the grid items within each row */
    width: 100%; /* Ensures the grid takes the full width of its container */
}

.circular-progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.circular-progress-container p {
margin-top: 10px;
color: #333;
font-size: 14px;
font-weight: bold;
text-align: center;
margin-bottom: 0; /* Ensure there's no extra space below the paragraph */
}

.health-parent {
    background: linear-gradient(to bottom, #D8F3DC 0%, #95D5B2 100%);
    min-height: 100vh; /* This ensures that .health-parent covers at least the height of the viewport */
    width: 100%; /* This ensures that .health-parent covers the full width */
    display: flex; /* If .health-parent is a flex container */
    flex-direction: column; /* If .health-parent has child elements that are laid out in a column */
  }

.hr-report {
margin: 0 auto;
margin-bottom: 30px;
display: block;
width: 50%;
}

.health-table {
    width: 100%;
    border-collapse: collapse;
}

.health-table th, .health-table td {
    border: 1px solid #e0e0e0;
    padding: 8px;
    text-align: left;
}

.horizontal-progress-bar {
    width: 100%;
}

.progress {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white; /* Text color */
    font-weight: bold; /* Bold text for better readability */
    transition: width 0.3s ease; /* Smooth transition for the progress bar animation */
}

.limit-text {
    display: flex;
    justify-content: space-between;
    font-size: '12px';
    color: '#666'; /* Text color */
}

