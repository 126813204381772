.body{
    display: grid;
    place-content: center center;
    place-items: center center;
    padding: 2%;
    /* box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5) */
}
@media only screen and (max-width: 600px) {
    .body {
      /* background-color: lightblue; */
        position: absolute;
    }
  }
.inst__container{
    grid-row: 1;
    grid-column: 1;
    /* position: absolute; */
    z-index: 1;
}
.skip__container{
    grid-row: 1;
    grid-column: 1;
    z-index: 2;
    place-self: start end;
    margin: 15px;
}
.skip__btn{
    background-color: #4CAF50; /* Green */
    border: none;
    border-radius: 20px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19); 
}
.skip__btn:hover{
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}
.info__container{
    justify-content: center;
    align-items: center;
    text-align: center;
}
.info__message{
    font-weight: 800;
    font-size: larger;
}
.webcam__container{
    display: grid;
    /* grid-auto-flow: dense; */
    /* position: relative; */
    /* height: max-content; */
    /* width: max-content; */
    place-content: center center;
    place-items: center center;
    /* box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5) */
    /* grid-auto-rows: minmax(100px,auto);  */
}
.webcam__video{
    /* display: flex; */
    grid-row: 1;
    grid-column: 1;
    z-index: -2;
    /* margin-left: auto;
    margin-right: auto; */
}

.timer__container{
    grid-row: 1;
    grid-column: 1;
    z-index: 0;
    place-self: start end;
    margin: 15px;
    opacity:0.8;
}
.timer__value{
    height: 100px;
    width: 100px;
    border-radius: 50px;
    text-align: center;
    outline: 0;
    border: 0;
    font-weight:bolder;
    font-size:x-large;
    color:green;
}
.button__container{
    grid-row: 1;
    grid-column: 1;
    z-index: 0;
    place-self: end start;
    margin: 15px;
    /* position:absolute;
    bottom: 8%;
    left: 5%; */
}
.start__button{
    background-color: #4CAF50; /* Green */
    border: none;
    border-radius: 20px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}
.start__button:hover{
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);

}
.upload__button{
    background-color:cornflowerblue; /* Green */
    border: none;
    border-radius: 20px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}
.upload__button:hover{
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);

}

.result__button{
    background-color:#4CAF50; /* Green */
    border: none;
    border-radius: 20px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}
.result__button:hover{
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);

}

.webcam__video, .face__overlay {
    transform: scaleX(-1);
}